<template>
    <!-- CONTENT ================================================== -->
    <div>

<!-- breadcrumb --> 
<div class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item"><a href="#"> <i class="fas fa-building"></i> </a></li>
          <li class="breadcrumb-item"> <i class="fas fa-chevron-right"></i> <a href="#">{{ translateAktif == 'id' ? 'Kontak Kami' : 'Contact Us'}}</a></li>
        </ol>
      </div>
    </div>
  </div>
</div>
<!--=================================
breadcrumb -->
    
<section class="space-ptb">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title">
          <h2>{{ translateAktif == 'id' ? 'Kontak Kami' : 'Contact Us'}}</h2>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="contact-address bg-light p-4">
          <div class="d-flex mb-3">
            <div class="contact-address-icon">
              <i class="flaticon-map text-primary font-xlll"></i>
            </div>
            <div class="ml-3">
              <h6>{{ translateAktif == 'id' ? 'Alamat' : 'Address'}}</h6>
              <p>- Grosir Batik Setono, Blok Sejahtera No 24, Kota Pekalongan & Cibeunying Kidul, Kota Bandung</p>
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="contact-address-icon">
              <i class="flaticon-email text-primary font-xlll"></i>
            </div>
            <div class="ml-3">
              <h6>Email</h6>
              <p>hasinahello@gmail.com</p>
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="contact-address-icon">
              <i class="flaticon-call text-primary font-xlll"></i>
            </div>
            <div class="ml-3">
              <h6>{{ translateAktif == 'id' ? 'Nomor HP' : 'Phone'}}</h6>
              <p>+62 857-4017-7785</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 mt-4 mt-lg-0">
        <div class="contact-form">
          <h4 class="mb-4">{{ translateAktif == 'id' ? 'Ada yang perlu disampaikan ?' : 'Is there anything that needs to be conveyed?'}}</h4>
          <form>
            <div class="form-row">
              <div class="form-group col-md-12">
                <textarea class="form-control" v-model="myMessage" rows="6" placeholder=".........."></textarea>
              </div>
              <div class="col-md-12">
                <a class="btn btn-primary" style="color:white;cursor:pointer" @click="sendMessage()">{{ translateAktif == 'id' ? 'Kirim Pesan' : 'Send Message'}}</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
</template>

<script>

    export default {
        name: 'Hasina',
        metaInfo: {
            title: 'Hasina',
            titleTemplate: '%s - Kontak Kami',
            meta: [
              {
                name: 'keywords',
                content: 'Hasina batik, Hasina konveksi, Hasina clothing, Hasina Merchandise, Hasina id'
              }
            ]
        },
        mounted() {
            this.getLocalStorage();
        },
        data() {
            return {
                translateAktif: this.$root.$translateAktif,
                username: "",
                password: "",
                submitted: false,
                myMessage:''
            }
        },
        methods: {
            getImage(url){
                if (url.match(this.baseUrl)) {
                    return url;
                } else {
                    return this.baseUrl + url;
                }
            },
            sendMessage(){
              const newURL = "https://wa.me/6285740177785?text=Halo+Hasina...+"+ this.myMessage;
              window.open(newURL, "_blank");
            },
            getLocalStorage(){
                this.translateAktif = localStorage.getItem('translate');
            }
        }
    }
</script>